const getCity = function() {
  return localStorage.getItem("city");
}

const setCity = function(city) {
  localStorage.setItem("city", city);
}

let city = document.querySelector(".vote-citys");

if(getCity() === null) {
  city.textContent = 'Казань';
  setCity('Казань');
} else {
  city.textContent = getCity();
}

let citys = document.querySelector(".citys-container");
citys.addEventListener("click", e => chooseCity(e));

const chooseCity = function(e) {
  if(e.target.tagName === 'P') {
    city.textContent = e.target.textContent;
    setCity(city.textContent);

    modal3.style.display='none';
  }
}
